<template>
  <el-dialog :title="'选择工资包'" :visible="dialogVisible" width="900px" @close="closeDialog()">
    <el-table :data="list" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="工资包名称" prop="name"></el-table-column>
      <el-table-column label="结算中心">
        <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
      </el-table-column>
      <el-table-column label="结算金额/元">
        <template slot-scope="scope">{{ scope.row.total || "0" }}</template>
      </el-table-column>
      <el-table-column label="发包时间">
        <template slot-scope="scope">{{ scope.row.releaseTime | dateVal }}</template>
      </el-table-column>
    </el-table>
    <div>
      <div style="margin-top: 20px; text-align: right">
        <span>开票总金额：</span>
        <el-input v-model="total" style="width: 200px"></el-input>
        <span>元</span>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
//查询列表接口
export const taskList = api()('task.list.json');
export const invoiceSelectInvoicePackage = api()(
  'invoice.select.invoice.package.json'
);

export default {
  props: {
    dialogVisible: Boolean,
    account: String,
    invoiceCode: String
  },
  data () {
    return {
      list: [],
      total: 0,
      selectList: []
    };
  },
  watch: {
    account: function (old, val) {
      if (old === val) return;
      this.getList();
    }
  },
  created () { },
  methods: {
    async getList () {
      const res = await taskList({
        pageNumber: 1,
        pageSize: 1000,
        enterpriseCode: this.account,
        state: '5',
        needInvoice: true
        // invoiceApplyCode: ""
      });
      this.list = res.list;
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
    },
    handleSelectionChange (val) {
      this.selectList = val;
      this.total = _.sum(val.map(it => it.total * 1));
    },
    async save () {
      await invoiceSelectInvoicePackage({
        applyCode: this.invoiceCode,
        packageCodes: this.selectList.map(it => it.code).join(',')
      });
      this.$message.success('工资包添加成功');
      this.closeDialog();
      this.$emit('close');
    }
  }
};
</script>

<style></style>
